import { BrowserRouter, Routes, Route } from "react-router-dom";
import ScrollToTop from './pages/ScrollToTop'; 
import { ServiceProvider } from './pages/ServiceContext';
import Navbar from './components/Navbar';
import Footer from "./components/Footer";
import Homepage from "./pages/Homepage";
import Services from "./pages/Ourservice";
import Bookapointment from "./pages/Bookapointment"; 
import Washing from "./pages/Washing";
import Paintprotectionfilm from "./pages/paintprotectionfilm";
import Creamiccoating from "./pages/Creamiccoating";
import Interiortreatment from "./pages/Interiortreatment";
import Airconditioner from "./pages/Airconditioner";
import Engineprotection from "./pages/Engineprotection";
import Headlamp from "./pages/Headlamp";
import Windshieldrestoration from "./pages/windshieldrestoration";
import Exteriortreatment from "./pages/Exteriortreatment";
import ANTIRUSTPROTECTION from "./pages/Antirustprotection";
import HOWWEWORK from "./pages/howwework";
import TermsnCondition from "./pages/TermsCondition";
import CookiesPolicy from "./pages/CookiesPolicy";
import PrivacyPolicy from "./pages/PrivacyPolicy";
import Aboutus from "./pages/Aboutus";
import ResponsiveLayout from "./pages/partner";
import whatsappicon from './image/whatsapp-icon.png';
import FAQPage from './pages/faq';
import LoginForm from './components/login';
import Signup from './components/Signup';
import ServiceListing from "./pages/ServiceListing";
import CustomerDetails from "./pages/CustomerDetails";
import PaymentDetails from './pages/PaymentDetails';
import BookingSummary from './pages/BookingSummary';
import ExteriortreatmentTable from './pages/ExteriortreatmentTable';
import InteriortreatmentTable from './pages/InteriortreatmentTable';

function App() {
  return (
    <div>
      <BrowserRouter>
        <Navbar />
        <ScrollToTop /> 
        <ServiceProvider>
        <Routes>
          <Route path="/" element={<Homepage />} />
          <Route path="/services" element={<Services />} />
          <Route path="/bookanappointment" element={<Bookapointment />} />
          <Route path="/foamwash" element={<Washing />} />
          <Route path="/paintprotectionfilm" element={<Paintprotectionfilm />} />
          <Route path="/ceramiccoating" element={<Creamiccoating />} />
          <Route path="/interiortreatment" element={<Interiortreatment />} />
          <Route path="/exteriortreatment" element={<Exteriortreatment />} />
          <Route path="/airconditioner" element={<Airconditioner />} />
          <Route path="/engineprotection" element={<Engineprotection />} />
          <Route path="/headlamprestoration" element={<Headlamp />} />
          <Route path="/windshieldrestoration" element={<Windshieldrestoration />} />
          <Route path="/antirustprotection" element={<ANTIRUSTPROTECTION />} />
          <Route path="/howwework" element={<HOWWEWORK />} />
          <Route path="/termsnondition" element={<TermsnCondition />} />
          <Route path="/privacypolicy" element={<PrivacyPolicy />} />
          <Route path="/cookiespolicy" element={<CookiesPolicy />} />
          <Route path="/about" element={<Aboutus />} />
          <Route path="/become-a-partner" element={<ResponsiveLayout />} />
          <Route path="/faq" element={<FAQPage />} />
          <Route path="/login" element={<LoginForm/>}/>
          <Route path="/signup" element={<Signup/>}/>
          <Route path="/ServiceListing" element={<ServiceListing/>} />
          <Route path="/CustomerDetails" element={<CustomerDetails/>} />
          <Route path="/PaymentDetails" element={<PaymentDetails/>} />
          <Route path='/BookingSummary' element={<BookingSummary/>} />
          <Route path="/ExteriortreatmentTable" element={<ExteriortreatmentTable/>} />
          <Route path="/InteriortreatmentTable" element={<InteriortreatmentTable/>} />         
        </Routes>
        </ServiceProvider>
        <Footer />
      </BrowserRouter> 
      <div className="whatsappicon"> <a href="https://wa.me/+919981040082" target="_blank"> <img src={whatsappicon} alt="" /></a> </div>
    </div>
  );
}

export default App;
