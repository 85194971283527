import React from "react";
import "./ExteriortreatmentTable.css"; 

const ExteriortreatmentTable = () => {
  return (
    <div className="treatment-table-container">
      <div className="treatment-details">
        <div className="treatment-detail-first">
          <h5>Treatment Name</h5>
          <p>UV-protection</p>
          <p>Paint Correction</p>
          <p>Surface Refinement</p>
          <p>Surface Correction</p>
        </div>

        <div className="treatment-detail">
          <h5>Recommended</h5>
          <p>Wax application for UV-protection</p>
          <p>Ideal for less than a year old cars</p>
          <p>Ideal for 1-3 yr old cars without paint peel-offs & scratches</p>
          <p>Ideal for surfaces with deep scratches</p>
        </div>

        <div className="treatment-detail">
          <h5>Process</h5>
          <p>Lasts 7-45 days. No machine process</p>
          <p>Rubbing compounds to treat swirl marks</p>
          <p>
            Cut compounds applied for surface finish followed by point
            correction
          </p>
          <p>
            Hard coarse compounds are used followed by surface refinement
            process
          </p>
        </div>

        <div className="treatment-detail">
          <h5>0-11 Months</h5>
          <p>Yes</p>
          <p>No</p>
          <p>Yes</p>
          <p>No</p>
        </div>
      </div>
    </div>
  );
};

export default ExteriortreatmentTable;
