import React, { useState , useContext } from 'react';
import './Washing.css'; 
import exterior1 from '../image/inside service/ppf2.jpg';
import exterior2 from '../image/inside service/exterior2.jpg';
import exterior3 from '../image/inside service/exterior3.jpg';
import exterior4 from '../image/inside service/exterior4.jpg';
import image from '../image/washcar.jpg';
import ServiceSidebar from './ServiceSidebar';
import {faXmark, faArrowRight} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ServiceContext } from './ServiceContext'; // Import the context
import { Link } from 'react-router-dom';



const Exteriortreatment= () => {
    const { addService, removeService } = useContext(ServiceContext); // Use the context
    const [isAdded1, setIsAdded1] = useState(false);
    const [isAdded2, setIsAdded2] = useState(false);
    const [isAdded3, setIsAdded3] = useState(false);
  
    const handleAddToCart1 = () => {
        if (!isAdded1) {
            setIsAdded1(true);
            addService(); // Increment global service count
        }
    };
  
    const handleCancel1 = () => {
        if (isAdded1) {
            setIsAdded1(false);
            removeService(); // Decrement global service count
        }
    };

    const handleAddToCart2 = () => {
        if (!isAdded2) {
            setIsAdded2(true);
            addService(); // Increment global service count
        }
    };
  
    const handleCancel2 = () => {
        if (isAdded2) {
            setIsAdded2(false);
            removeService(); // Decrement global service count
        }
    };

    const handleAddToCart3 = () => {
        if (!isAdded3) {
            setIsAdded3(true);
            addService(); // Increment global service count
        }
    };
  
    const handleCancel3 = () => {
        if (isAdded3) {
            setIsAdded3(false);
            removeService(); // Decrement global service count
        }
    };

  
    return (
        <div className='washingpage-container' style={{display:'flex'}}>
        <ServiceSidebar />

               <div className="main-contant">
    <div className="services-contant">
    <div className="services-contact-image">
                     <img src={exterior1} alt="Car" />
                 </div>
            <div className="services-contact-form">
                <h2 style={{ fontFamily: '"Chakra Petch", sans-serif' }}>Exterior Care</h2>
                <p>Improving the vehicle's exterior appearance through buffing and polishing painted surfaces.</p>  
                <h3 style={{ fontFamily: '"Chakra Petch", sans-serif' }}>Benefits:</h3>
   <li>	Removes dirt, grime, and contaminants.</li>
   <li>	Protects and preserves the surface.</li>
   <li>	Restores paint shine and luster.</li>
   <li>	Increases resale value.</li>
   <li>	Eliminates swirl marks and scratches.</li></div> 

</div>
    
           <div className="midd-contant"  >
            <div className="midd-contact-form">
                <h2 style={{ fontFamily: '"Chakra Petch", sans-serif' }}>Paint Correction</h2>
                <p>Content to be provided</p>
               </div>
               <div className="midd-contact-image">
                <img src={exterior2} alt="Car" />
                <div className='add-cart'>
                {isAdded1 &&(
                <button className='cencel' onClick={handleCancel1}>
                    <FontAwesomeIcon icon={faXmark} />
                    </button> 
                )}
                              <button className={`added ${isAdded1 ? 'added-active' : ''}`} 
        onClick={handleAddToCart1}
    >
                {isAdded1 ? "Service Added" : "Add To Cart"}
              </button>            
            </div>
            </div></div>

               <div className="midd-contant">
            <div className="midd-contact-form">
                <h2 style={{ fontFamily: '"Chakra Petch", sans-serif' }}>Exterior Enrichment</h2>
                <p>Content to be provided</p>
 </div>      
                 <div className="midd-contact-image">
                     <img src={exterior3} alt="Car" />
                     <div className='add-cart'>
                {isAdded2 &&(
                <button className='cencel' onClick={handleCancel2}>
                    <FontAwesomeIcon icon={faXmark} />
                    </button> 
                )}
                              <button className={`added ${isAdded2 ? 'added-active' : ''}`} 
        onClick={handleAddToCart2}
    >
                {isAdded2 ? "Service Added" : "Add To Cart"}
              </button>            
            </div>
                 </div></div>
     
               <div className="midd-contant">
            <div className="midd-contact-form">
                <h2 style={{ fontFamily: '"Chakra Petch", sans-serif' }}>Surface Correction</h2>
                <p>Content to be provided</p>
               </div>
               <div className="midd-contact-image">
                <img src={exterior4} alt="Car" />
                <div className='add-cart'>
                {isAdded3 &&(
                <button className='cencel' onClick={handleCancel3}>
                    <FontAwesomeIcon icon={faXmark} />
                    </button> 
                )}
                              <button className={`added ${isAdded3 ? 'added-active' : ''}`} 
        onClick={handleAddToCart3}
    >
                {isAdded3 ? "Service Added" : "Add To Cart"}
              </button>            
            </div>
            </div>
        </div>
       <Link to="/ServiceListing"> <div className='service-partnerarrow'> Proceed to Select Service Partner <FontAwesomeIcon icon={faArrowRight} /></div></Link>
        </div>
        </div>
    );
};

export default Exteriortreatment;
