import React, { useState ,useContext} from 'react';
import './Washing.css'; 
import ppf from '../image/inside service/PPf.jpg';
import ppf2 from '../image/inside service/ppf2.jpg'
import image from '../image/washcar.jpg';
import ServiceSidebar from './ServiceSidebar';
import {faXmark, faArrowRight} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ServiceContext } from './ServiceContext'; // Import the context
import { Link } from 'react-router-dom';


const Paintprotectionfilm = () => {

    const { addService, removeService } = useContext(ServiceContext); // Use the context
    const [isAdded1, setIsAdded1] = useState(false);
  
    const handleAddToCart1 = () => {
        if (!isAdded1) {
            setIsAdded1(true);
            addService(); // Increment global service count
        }
    };
  
    const handleCancel1 = () => {
        if (isAdded1) {
            setIsAdded1(false);
            removeService(); // Decrement global service count
        }
    };


    return (
        <div className='washingpage-container' style={{display:'flex'}}>
        <ServiceSidebar />

               <div className="main-contant">
               <div className="midd-contant">
            <div className="services-contact-form">
                <h2 style={{ fontFamily: '"Chakra Petch", sans-serif' }}>Paint Protection Film (PPF)</h2>
                <p>A thin, durable layer (150-200 microns thick, about 20-25 times thicker than a ceramic coating) applied to a car's painted surface to protect the paint from damage.</p>
                <h3 style={{ fontFamily: '"Chakra Petch", sans-serif' }}>Benefits :</h3>
                <li>	Shields against scratches, scuffs, and swirl marks</li>
<li>	Protects from rock chips, road debris, and bug splatters</li>
<li>	Maintains the paint's gloss and shine</li>
<li>	Easy to clean and maintain</li>
<li>	Provides UV protection</li>
<h3 style={{ fontFamily: '"Chakra Petch", sans-serif' }}>Types :</h3>
<li>	PVC (Polyvinyl Chloride) PPF: Most affordable option
</li>
<li>	TPH (Thermoplastic Poly Hydrocarbon) PPF: Mid-range, without self-healing properties
</li>
<li>	TPU (Thermoplastic Polyurethane) PPF: Premium, most effective option with excellent self-healing, non-yellowing, and elastic properties
</li>
<p>PPF comes in glossy, matte, black, and colored finishes
</p>


</div>     
                 <div className="services-contact-image">
                     <img src={ppf2} alt="Car" />
                     <div className='add-ppf-cart' style={{display:'flex' , justifyContent:'space-around'}}>
                        <a href='/ServiceListing' style={{color:'#FFBE00', fontFamily: '"Chakra Petch", sans-serif',marginRight:'50px' , marginTop:'25px'}}> Talk to our Experts</a>
                        <div className='add-cart'>
                {isAdded1 &&(
                <button className='cencel' onClick={handleCancel1}>
                    <FontAwesomeIcon icon={faXmark} />
                    </button> 
                )}
                              <button className={`added ${isAdded1 ? 'added-active' : ''}`} 
        onClick={handleAddToCart1}
    >
                {isAdded1 ? "Service Added" : "Add To Cart"}
              </button>            
            </div>
                       </div>
                 </div>
        </div>
        <Link to="/ServiceListing" ><div className='service-partnerarrow'> 
            Proceed to Select Service Partner <FontAwesomeIcon icon={faArrowRight} />
          </div> </Link> 
         </div>
        </div>
      
    );
};

export default Paintprotectionfilm;
