import React, { useEffect, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleCheck } from '@fortawesome/free-solid-svg-icons';
import { useLocation } from 'react-router-dom';

const StatusSidebar = () => {
  const [activeStep, setActiveStep] = useState(1);
  const location = useLocation(); // Hook to get the current route

  useEffect(() => {
    // Update the step based on the current route
    if (location.pathname === '/ServiceListing') {
      setActiveStep(1);
    } else if (location.pathname === '/CustomerDetails') {
      setActiveStep(2);
    } else if (location.pathname === '/PaymentDetails') {
      setActiveStep(3);
    } else if (location.pathname === '/PaymentConfirmation') {
      setActiveStep(4);
    } else if (location.pathname === '/BookingSummary') {
      setActiveStep(5);
    } else {
      // Reset to the first step for any other route
      setActiveStep(1);
    }
  }, [location.pathname]); // Re-run this effect when the route changes

  // Assign colors based on the active step
  const getIconColor = (step) => (step <= activeStep ? '#FFBE00' : '#fff');

  return (
    <div className="Status-sidebar">
      <h3>Customer Details</h3>
      <ul>
        <li>
          <FontAwesomeIcon icon={faCircleCheck} style={{ color: getIconColor(1) }} /> Service Selection
        </li>
        <li>
          <FontAwesomeIcon icon={faCircleCheck} style={{ color: getIconColor(2) }} /> Service Provider Selection
        </li>
        <li>
          <FontAwesomeIcon icon={faCircleCheck} style={{ color: getIconColor(3) }} /> Customer Details
        </li>
        <li>
          <FontAwesomeIcon icon={faCircleCheck} style={{ color: getIconColor(4) }} /> Payment Confirmation
        </li>
        <li>
          <FontAwesomeIcon icon={faCircleCheck} style={{ color: getIconColor(5) }} /> Booking Summary
        </li>
      </ul>
    </div>
  );
};

export default StatusSidebar;
