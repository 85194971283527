import React, { useState } from "react";
import "./part.css"; // Import the CSS file for styling
import Pp from '../image/Car2.jpg'; // Import the image
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus } from '@fortawesome/free-solid-svg-icons';

const ResponsiveLayout = () => {
  const [showForm, setShowForm] = useState(true);
  const [currentSection, setCurrentSection] = useState(1);
  const [formData, setFormData] = useState({
    companyName: '',
    businessType: '',
    registeredAddress: '',
    city: '',
    state: '',
    postalCode: '',
    gstn: '',
    otherService: '',
    website: '',
    storeclosed: 'sunday',
    otherBusinessType: '',
    yearEstablished: '',
    numEmployees: '',
    primaryContactName: '',
    primaryContactTitle: '',
    primaryContactEmail: '',
    primaryContactPhone: '',
    altContactName: '',
    altContactTitle: '',
    altContactEmail: '',
    altContactPhone: '',
    servicesOffered: '',
    serviceArea: '',
    serviceTurnaround: '',
    operatingHoursWeekdaysStart: '',
    operatingHoursWeekdaysEnd: '',
    operatingHoursWeekendsStart: '',
    operatingHoursWeekendsEnd: '',
    numServiceBays: '',
    equipmentAvailable: '',
    certifications: '',
    partnershipGoals: '',
    termsConditions: '',
    documentsRequired: '',
    additionalComments: '',
    signatoryName: '',
    signatoryTitle: '',
    signature: '',
    date: ''
  });
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [showSuccessMessage, setShowSuccessMessage] = useState(false);
  const [errors, setErrors] = useState({});

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value
    });
  };

  const handleNext = () => {
    let currentErrors = {};

    // Validate section 1 (Company Information)
    if (currentSection === 2) {
      if (!formData.companyName) currentErrors.companyName = 'Company Name is required';
      if (!formData.businessType) currentErrors.businessType = 'Business Type is required';
      if (!formData.registeredAddress) currentErrors.registeredAddress = 'Address is required';
      if (!formData.city) currentErrors.city = 'City is required';
      if (!formData.state) currentErrors.state = 'State is required';
      if (!formData.postalCode) currentErrors.postalCode = 'Postal Code is required';
    }

    // Validate section 2 (Contact Information)
    if (currentSection === 1) {
      if (!formData.primaryContactName) currentErrors.primaryContactName = 'Primary Contact Name is required';
      if (!formData.primaryContactTitle) currentErrors.primaryContactTitle = 'Primary Contact Title is required';
      if (!formData.primaryContactEmail) currentErrors.primaryContactEmail = 'Primary Contact Email is required';
      if (!formData.primaryContactPhone) currentErrors.primaryContactPhone = 'Primary Contact Phone is required';
      if (!formData.altContactName) currentErrors.altContactName = 'Alternative Contact Name is required';
      // if (!formData.altContactTitle) currentErrors.altContactTitle = 'Alternative Contact Title is required';
      // if (!formData.altContactEmail) currentErrors.altContactEmail = 'Alternative Contact Email is required';
      // if (!formData.altContactPhone) currentErrors.altContactPhone = 'Alternative Contact Phone is required';
    }

    // Validate section 3 (Service Details)
    if (currentSection === 3) {
      if (!formData.servicesOffered) currentErrors.servicesOffered = 'Services Offered is required';
      if (!formData.serviceArea) currentErrors.serviceArea = 'Service Area is required';
      // if (!formData.serviceTurnaround) currentErrors.serviceTurnaround = 'Turnaround Time is required';
      if (!formData.operatingHoursWeekdaysStart) currentErrors.operatingHoursWeekdaysStart = 'Weekday Start Time is required';
      if (!formData.operatingHoursWeekdaysEnd) currentErrors.operatingHoursWeekdaysEnd = 'Weekday End Time is required';
      // if (!formData.operatingHoursWeekendsStart) currentErrors.operatingHoursWeekendsStart = 'Weekend Start Time is required';
      // if (!formData.operatingHoursWeekendsEnd) currentErrors.operatingHoursWeekendsEnd = 'Weekend End Time is required';
      if (!formData.numServiceBays) currentErrors.numServiceBays = 'Number of Service Bays is required';
      // if (!formData.equipmentAvailable) currentErrors.equipmentAvailable = 'Equipment Available is required';
      // if (!formData.certifications) currentErrors.certifications = 'Certifications is required';
    }

    // Validate section 4 (Partnership Goals and Terms)
    if (currentSection === 4) {
      // if (!formData.partnershipGoals) currentErrors.partnershipGoals = 'Partnership Goals are required';
      // if (!formData.additionalComments) currentErrors.additionalComments = 'Additional Comments are required';
    }

    // Check if there are any errors
    if (Object.keys(currentErrors).length > 0) {
      setErrors(currentErrors); // Set errors to display
    } else {
      setErrors({}); // Clear errors
      setCurrentSection(currentSection + 1); // Proceed to the next section
    }
  };

  const handlePrevious = () => {
    setCurrentSection(currentSection - 1);
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    // Make a POST request to the API with the form data
    fetch('http://localhost:5000/api/partners/add', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(formData),
    })
      .then(response => {
        if (response.ok) {
          // Handle successful response
          setIsSubmitted(true);
          setShowSuccessMessage(true);
          setTimeout(() => {
            setShowSuccessMessage(false);
          }, 3000); // Hide the success message after 3 seconds

          // Optionally reset form data after successful submission
          setFormData({
            companyName: '',
            businessType: '',
            registeredAddress: '',
            city: '',
            state: '',
            postalCode: '',
            gstn: '',
            website: '',
            yearEstablished: '',
            numEmployees: '',
            otherBusinessType: '',
            primaryContactName: '',
            primaryContactTitle: '',
            primaryContactEmail: '',
            primaryContactPhone: '',
            altContactName: '',
            altContactTitle: '',
            altContactEmail: '',
            altContactPhone: '',
            storeclosed: 'sunday',
            otherService: '',
            servicesOffered: '',
            serviceArea: '',
            serviceTurnaround: '',
            operatingHoursWeekdaysStart: '',
            operatingHoursWeekdaysEnd: '',
            operatingHoursWeekendsStart: '',
            operatingHoursWeekendsEnd: '',
            numServiceBays: '',
            equipmentAvailable: '',
            certifications: '',
            partnershipGoals: '',
            termsConditions: '',
            documentsRequired: '',
            additionalComments: '',
            signatoryName: '',
            signatoryTitle: '',
            signature: '',
            date: ''
          });
          setCurrentSection(1); // Reset to the first section
          // Keep the form visible after submission
          // setShowForm(false); // Optionally hide the form after submission
        } else {
          // Handle errors
          console.error('Form submission failed');
        }
      })
      .catch(error => {
        console.error('Error:', error);
      });
  };

  const displayError = (field) => {
    return errors[field] && <span className="error">{errors[field]}</span>;
  };
  const [uploadedFiles, setUploadedFiles] = useState({});

  const handleFileChange = (event, category) => {
    const file = event.target.files[0];
    if (file && (file.type === 'application/pdf' || file.type.startsWith('image/'))) {
      setUploadedFiles((prevFiles) => ({
        ...prevFiles,
        [category]: file,
      }));
    } else {
      alert('Only PDF and JPG files are allowed');
    }
  };

  const uploadCategories = [
    'Business License/Registration Certificate',
    'Proof Of Insurance',
    'List Of Services And Pricing',
    'Photos Of Facility (1 Inside & 1 Outside)',
    'Any Other Relevant Certifications Or Documents'
  ];
  return (
    <div className="container-part">
      {showForm && (
        <div className="form-container-part">
          {isSubmitted && showSuccessMessage && (
            <p className="success-message-part">Form submitted successfully!</p>
          )}
          <form onSubmit={handleSubmit}>
            {currentSection === 1 && (
               <>
          <h3> CONTACT INFORMATION </h3>
               <div className="two">
               <div>
                 <label>Partner Name <span>*</span></label>
                 <input
                   type="text"
                   name="primaryContactName"
                   value={formData.primaryContactName}
                   onChange={handleChange}
                   required
                 />
                 {displayError('primaryContactName')}
               </div>
               <div>
                 <label>Position/Title <span>*</span></label>
                 <input
                   type="text"
                   name="primaryContactTitle"
                   value={formData.primaryContactTitle}
                   onChange={handleChange}
                   required
                 />
                 {displayError('primaryContactTitle')}
               </div>
               </div>
               <div className="two">

               <div>
                 <label>Email Address <span>*</span></label>
                 <input
                   type="email"
                   name="primaryContactEmail"
                   value={formData.primaryContactEmail}
                   onChange={handleChange}
                   required
                 />
                 {displayError('primaryContactEmail')}
               </div>
               <div>
                 <label>WhatsApp Number <span>*</span></label>
                 <input
                   type="number"
                   name="primaryContactPhone"
                   value={formData.primaryContactPhone}
                   onChange={handleChange}
                   required
                 />
                 {displayError('primaryContactPhone')}
               </div>
               </div>

               <div className="two">
               <div>
                
                 <label>Contact No. <span>*</span></label>
                 <input
                   type="text"
                   name="altContactName"
                   value={formData.altContactName}
                   onChange={handleChange}
                   required
                 />
                 {displayError('altContactName')}
               </div>
               <div>
                 <label>Alternate Contact No. <span>*</span></label>
                 <input
                   type="text"
                   name="altContactTitle"
                   value={formData.altContactTitle}
                   onChange={handleChange}
                   required
                 />
                  {displayError('altContactTitle')}
               </div>
               </div>

               <button className="submit-bt" type="button" onClick={handleNext}>Next</button>

             
             </>
            )}
            {currentSection === 2 && (
             <>
             <h3>COMPANY DETAILS </h3>
             <div style={{display:'flex' , gap:'45px'}}>
             <div>
               <label>Company Name: <span>*</span></label>
               <input
                 type="text"
                 name="companyName"
                 placeholder="Enter Your Company Name "
                 value={formData.companyName}
                 onChange={handleChange}
                 required
               />
                 {displayError('companyName')}
             </div>
             <div>
             <div>
               <label>Primary Contact No <span>*</span></label>
               <input
                 type="text"
                 name="companyName"
                 placeholder="000-000-0000 "
                 value={formData.companyName}
                 onChange={handleChange}
                 required
               />
                 {displayError('companyName')}
             </div>
             <div>
               <label>Secondary Contact No <span>*</span></label>
               <input
                 type="text"
                 name="companyName"
                 placeholder="000-000-0000 "
                 value={formData.companyName}
                 onChange={handleChange}
                 required
               />
                 {displayError('companyName')}
             </div>
             <div>
               <label>Website Address</label>
               <input
                 type="text"
                 placeholder="Enter Website url"
                 name="website"
                 value={formData.website}
                 onChange={handleChange}
                 required
               />
             </div>
  <label>Business Type <span>*</span></label>
  <select
    name="businessType"
    placeholder="Enter Your Designation"
    value={formData.businessType}
    onChange={handleChange}
    required
  >
    <option value="">Select...</option>
    <option value="Car Detailing Studio">Fanchise Model </option>
    <option value="Economical Car Wash Center">Car Detailling Studio </option>
    <option value="Local Detailing Studio">Multi-brand Workshop</option>
    <option value="Local Detailing Studio">Economical Car Wash Center </option>
    <option value="Other">Other (please specify)</option>
  </select>

  {displayError('businessType')}

  {/* Conditionally render input field when "Other" is selected */}
  {formData.businessType === 'Other' && (
    <div>
      <label>Others:</label>
      <input
        type="text"
        name="otherBusinessType"
        value={formData.otherBusinessType || ''}
        onChange={handleChange}
        placeholder="Give Description for Your Shop"
        required
      />
    </div>
  )}
</div>

             </div>
             <div>
               <label>Store Address-1 <span>*</span></label>
               <input
                 type="text"
                 name="registeredAddress"
                 value={formData.registeredAddress}
                 onChange={handleChange}
                 required
               />
               {displayError('registeredAddress')}
             </div>
             <div>
               <label>Store Address-2 <span>*</span></label>
               <input
                 type="text"
                 name="registeredAddress"
                 value={formData.registeredAddress}
                 onChange={handleChange}
                 required
               />
               {displayError('registeredAddress')}
             </div>
             <div>
               <label> Select State <span>*</span></label>
               <input
                 type="text"
                 name="state"
                 placeholder="Select State"
                 value={formData.state}
                 onChange={handleChange}
                 required
               />
                {displayError('state')}
             </div>
             <div style={{display:'flex' , gap:'15px'}}>
             <div>
               <label>City <span>*</span></label>
               <input
                 type="text"
                 name="city"
                 placeholder="Select City"
                 value={formData.city}
                 onChange={handleChange}
                 required
               />
               {displayError('city')}
             </div>
             <div>
               <label style={{whiteSpace:"nowrap"}}>Pin Code <span>*</span></label>
               <input
                 type="text"
                 name="postalCode"
                 placeholder="Enter Pin Code"
                 value={formData.postalCode}
                 onChange={handleChange}
                 required
               />
               {displayError('postalCode')}
             </div>
             <div>
               <label style={{whiteSpace:"nowrap"}}>Official Mail Address For Communication<span>*</span></label>
               <input
                 type="text"
                 name="postalCode"
                 placeholder="Official Mail Address For Communication"
                 value={formData.postalCode}
                 onChange={handleChange}
                 required
               />
               {displayError('postalCode')}
             </div>
             </div>
              <button className="submit-bt" type="button" onClick={handlePrevious}>Go Back</button>
              <button className="submit-bt" type="button" onClick={handleNext}>Next</button>
           </>
            )}
            {currentSection === 3 && (
              <>
                <h3>SERVICE LISTINGS</h3>
                <div>
                  <div>
  <input
    type="checkbox"
    name="servicesOffered"
    value="Exterior Detailing"
    onChange={handleChange}
  /> Exterior Detailing<br/>

  <input
    type="checkbox"
    name="servicesOffered"
    value="Interior Detailing"
    onChange={handleChange}
  /> Interior Detailing<br/>

  <input
    type="checkbox"
    name="servicesOffered"
    value="Full-Service Car Wash"
    onChange={handleChange}
  /> Car Wash<br/>

  <input
    type="checkbox"
    name="servicesOffered"
    value="Ceramic Coating"
    onChange={handleChange}
  /> Ceramic Coating<br/>

  <input
    type="checkbox"
    name="servicesOffered"
    value="Paint Protection Film"
    onChange={handleChange}
  /> Paint Protection Film<br/>

  <input
    type="checkbox"
    name="servicesOffered"
    value="Other"
    onChange={handleChange}
  /> Add more <br/>

  {formData.servicesOffered.includes('Other') && (
    <input
      type="text"
      name="otherService"
      value={formData.otherService || ''}
      onChange={handleChange}
      placeholder="Add Service"
      required
    />
  )}

  {displayError('servicesOffered')}
</div>

                </div>
                <div className="two">
                <div>
                  <label>Service Area Coverage: <span>*</span></label>
                  <select
                    name="serviceArea"
                    placeholder="select"
                    value={formData.serviceArea}
                    onChange={handleChange}
                    required
                  >
                    <option value="">Select...</option>
                    <option value="Local">Local</option>
                    <option value="Regional">Regional</option>
                    <option value="National">National</option>
                  </select>
                  {displayError('serviceArea')}
                </div>

                <div>
                  <label>No. of Service Employees <span>*</span></label>
                  <input
                    type="number"
                    name="numServiceBays"
                    value={formData.numServiceBays}
                    onChange={handleChange}
                    required
                  />
                   {displayError('numServiceBays')}
                </div>
                </div>
                <div className="two121">
               
                <div style={{display:'flex' , flexDirection:'column'}}>
                <h3>Operating Hours<br /> (Weekdays) <span>*</span></h3>
                <div>
                  <label>Start:</label>
                  <input
                    type="time"
                    name="operatingHoursWeekdaysStart"
                    value={formData.operatingHoursWeekdaysStart}
                    onChange={handleChange}
                     inputmode="numeric"
                    required
                  /><br/>
                   {displayError('operatingHoursWeekdaysStart')}
                </div>
                <div>
                  <label>End:</label>
                  <input
                    type="time"
                    name="operatingHoursWeekdaysEnd"
                    value={formData.operatingHoursWeekdaysEnd}
                    onChange={handleChange}
                    placeholder="HH:MM"
                     inputmode="numeric"
                    required
                  /><br/>
                    {displayError('operatingHoursWeekdaysEnd')}
                </div>
                </div>
               
                <div style={{display:'flex' , flexDirection:'column'}}>
                <h3>Operating Hours<br /> (Weekends) </h3>
                <div>
                  <label>Start:</label>
                  <input
                    type="time"
                    name="operatingHoursWeekendsStart"
                    value={formData.operatingHoursWeekendsStart}
                    onChange={handleChange}
                    required
                  /><br/>
                </div>
                <div>
                  <label>End:</label>
                  <input
                    type="time"
                    name="operatingHoursWeekendsEnd"
                    value={formData.operatingHoursWeekendsEnd}
                    onChange={handleChange}
                    required
                  /><br/>
                </div>
                </div>
                </div>

<div className="two">
<div style={{width:'72%'}}>
               <label>Store Closing Days</label>
               <select
                 name="storeclosed"
                 placeholder='Specify Store Off days'
                 value={formData.storeclosed}
                 onChange={handleChange}
                 required
               >
                 <option value="Sunday">Sunday</option>
                 <option value="Monday">Monday</option>
                 <option value="Tuesday">Tuesday</option>
                 <option value="Wednesday">Wednesday</option>
                 <option value="Thursday">Thursday</option>
                 <option value="Friday">Friday</option>
                 <option value="Saturday">Saturday</option>
               </select>
             </div>
             </div>
                <div className="btt">
                <button  className="submit-bt" type="button" onClick={handlePrevious}>Go Back</button>
                <button  className="submit-bt" type="button" onClick={handleNext}>Next</button>
                </div>
              </>
            )}
            {currentSection === 4 && (
              <>

<div className="documentation-section">
      <h1>Documentation</h1>
      <div className="upload-list">
        {uploadCategories.map((category, index) => (
          <div key={index} className="upload-item">
            <label>{category}</label>
            <input
              type="file"
              accept=".pdf, image/*"
              id={`file-${index}`} 
              onChange={(e) => handleFileChange(e, category)}
              style={{display:'none'}}
            />
            <label htmlFor={`file-${index}`} className="upload-icon" style={{color:'#fff'}}>
              <FontAwesomeIcon icon={faPlus} />
            </label>
            {uploadedFiles[category] && <p>Uploaded: {uploadedFiles[category].name}</p>}
          </div>
        ))}
      </div>

      <div className="tnc-section">
        <input type="checkbox" id="tnc" />
        <p> TnC and Policies</p>
        <label htmlFor="tnc">
          I have read & Accepted <a href="#" style={{color:'#FFBE00'}}>Terms & Conditions</a><span>*</span>
        </label>
      </div>
    </div>
                <button className="submit-bt" type="button" onClick={handlePrevious}>Go Back</button>
                <button className="submit-bt" type="submit">Next</button>
              </>
            )}
          </form>
          {showSuccessMessage && <div className="success-message">Form submitted successfully!</div>}
        </div>
      )}
    </div>
  );
};

export default ResponsiveLayout;


