import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus, faMinus } from '@fortawesome/free-solid-svg-icons';
import "./faq.css";

const FAQ = () => {
  const [activeIndex, setActiveIndex] = useState(null);

  const faqs = [
    {
      question: '1.What Is AutoKaar?',
      answer:'AutoKaar is an all-in-one platform that connects customers with a wide network of professional car detailing studios. Our platform allows you to easily browse, compare, and book car care services at the best detailing studios in your area with just one click.',
     
    },
    {
      question: '2.What Problem Is AutoKaar Helping Me Address?',
      answer: (
        <div>
          AutoKaar is a comprehensive platform that connects customers with a wide network of professional car detailing studios in nearby locations. It addresses issues such as:
          <ul>
            <li>Ensuring quality assurance for the price paid</li>
            <li>Providing predefined pricing</li>
            <li>Minimizing waiting times</li>
            <li>Monitoring product usage</li>
            <li>Facilitating conflict resolution</li>
            <li>Ensuring transparency throughout every step of the process</li>
          </ul>
        </div>
      ),    },
    {
      question: '3.What types of services can I book through AutoKaar?',
      answer: 'AutoKaar helps resolve various issues related to finding and booking car detailing services.'
    },
    {
      question: '4.How do I find car detailing studios near me?',
      answer: 'Simply enter your location or enable location access for AutoKaar. Our platform will show you a list of nearby detailing studios, including their ratings, reviews, and service prices. You can filter the results by service type, price range, or customer rating.'
    },
    {
      question: '5.How do I book an appointment?',
      answer: 'Enter your budget, and our platform will display tailored results. Choose a service provider by location or service type, filtering by exterior, interior, or full car detailing. Compare prices, read reviews, and select the best fit. Book your appointment, confirm details, and enjoy secure payment. A driver will pick up your car only after you share OTP with him.',
    },
    {
      question: '6.Can I cancel or reschedule my appointment?',
      answer: 'Yes'
    },
    {
      question: '7.How do I pay for the services?',
      answer: 'AutoKaar offers multiple payment options, including UPI, credit/debit cards, mobile wallets, and online banking. You can pay securely through the platform during the booking process or opt to pay directly at the studio.'
    },
    {
      question: '8.Does AutoKaar offer any discounts or promotions?',
      answer: 'Yes, AutoKaar frequently offers exclusive discounts and promotions in collaboration with our partner studios. Keep an eye on our promotions section or subscribe to our newsletter to stay updated.'
    },
  ];

  const toggleFAQ = (index) => {
    setActiveIndex(activeIndex === index ? null : index); // Toggle between open/close
  };

  return (
    <div className="faq-section">
      <h1>Frequently Asked Questions</h1>
      <div className="faq-list">
        {faqs.map((faq, index) => (
          <div key={index} className="faq-item">
            <div className="faq-question" onClick={() => toggleFAQ(index)}>
              <span>{faq.question}</span>
              <FontAwesomeIcon icon={activeIndex === index ? faMinus : faPlus} className="faq-icon" />
            </div>
            {activeIndex === index && (
              <div className="faq-answer">
                {faq.answer}
              </div>
            )}
          </div>
        ))}
      </div>
      <div className="still-have-question">
        <h2>Still Have A Question?</h2>
        <p>Contact customer support for more help & queries.</p>
        <a href="tel:+919981040082">+919981040082</a>
      </div>
    </div>
  );
};

export default FAQ;
