import React, { useState, useEffect } from 'react';
import './ServiceListing.css';
import ServiceListingSidebar from './ServiceListingSidebar';
import { faArrowRight } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import servicelogo from '../image/image bg home.jpg';
import { Link } from 'react-router-dom';
import { SlCalender } from 'react-icons/sl';
import { ImPencil } from 'react-icons/im';
import { FaCar } from 'react-icons/fa';

const servicesData = [
  {
    id: 1,
    name: 'Carz Cafe',
    description: 'Improving the vehicle’s exterior appearance through buffing and polishing painted surfaces.',
    rating: 4.8,
    location: 'Kolar',
    distance: '2–5kms',
    price: '5000',
  },
  {
    id: 2,
    name: 'The Detail Mafia',
    description:
      'Improving the vehicle’s exterior appearance through buffing and polishing painted surfaces.',
    rating: 4.8,
    location: 'Kolar',
    distance: '2–5kms',
    price: '9000',
    logo: '/images/detailmafialogo.png',
  },
  {
    id: 3,
    name: 'Carz Spa',
    description:
      'Improving the vehicle’s exterior appearance through buffing and polishing painted surfaces.',
    rating: 4.8,
    location: 'Kolar',
    distance: '2–5kms',
    price: '8000',
    logo: '/images/carzspa.png',
  },
  {
    id: 4,
    name: 'Detailing Devils',
    description:
      'Improving the vehicle’s exterior appearance through buffing and polishing painted surfaces.',
    rating: 4.8,
    location: 'Kolar',
    distance: '2–5kms',
    price: '8000',
    logo: '/images/detailingdevils.png',
  },
  {
    id: 5,
    name: 'Moto Craftz',
    description:
      'Improving the vehicle’s exterior appearance through buffing and polishing painted surfaces.',
    rating: 4.8,
    location: 'Kolar',
    distance: '2–5kms',
    price: '8000',
    logo: '/images/motocraftz.png',
  },
  {
    id: 6,
    name: 'Spa My Car',
    description:
      'Improving the vehicle’s exterior appearance through buffing and polishing painted surfaces.',
    rating: 4.8,
    location: 'Kolar',
    distance: '2–5kms',
    price: '8000',
    logo: '/images/spamycar.png',
  },
];
let date = new Date().toDateString();

const ServiceListing = ({ onStepComplete }) => {
  const [services, setServices] = useState(servicesData);
  const [isPopupOpen, setIsPopupOpen] = useState(true); // State to control the popup
  const [showContent, setshowContent] = useState(false);

  const handleSelect = (id) => {
    setServices(services.map(service => 
      service.id === id ? { ...service, selected: !service.selected } : service
    ));
    onStepComplete(); // Call this to update the step as soon as a service is selected
  };

  // Close the popup when the user clicks "OK"
  const handleClosePopup = () => {
    setIsPopupOpen(false);
  };

  useEffect(() => {
    setIsPopupOpen(true);
  }, []);

const showDetailClickHandler = () => {
    setshowContent(!showContent);
  };

  return (
    <div className='washingpage-container' style={{ display: 'flex' }}>
      <ServiceListingSidebar />
      <div className="service-container">
        <h1> Service Providers </h1>
        <div className="service-header">
          <h1> Service Providers </h1>
          <div className="date-time">
            {' '}
            <span className="calender">
              <SlCalender />
            </span>{' '}
            {date}{' '}
            <span className="car">
              {' '}
              <FaCar />
            </span>{' '}
            BMW-X5{' '}
            <button className="edit">
              <ImPencil className="edit-pencil" />
              Edit
            </button>
          </div>
        </div>

        {/* Popup component */}
        {isPopupOpen && (
          <div className="popup-overlay">
            <div className="popup-content">
              <div className='cencel-close'> <button onClick={handleClosePopup}> X <br></br>close</button></div>
              <h2>Add Your Vehicle Details</h2>
              <div className="popup-form">
              <div className="popup-row">
              <div className="popup-column">
                <label> Select Brand </label>
                <input type="text" placeholder="Choose Your Vehicle Brand" />
              </div>
              <div className="popup-column">
              <label> Select Model</label>
                <input type="text" placeholder="Choose Your Vehicle Model"/>
              </div>
            </div>
            <div className="popup-row">
              <div className="popup-column">
                <label> Select Fuel Type </label>
                <input type="text" placeholder="fuel type .e.g. petrol" />
              </div>
              <div className="popup-column">
              <label>Select Transmission/Variant </label>
                <input type="text" placeholder="e.g manual/automatic"/>
              </div>
            </div>
            <div className="popup-row-center">
            <div className="popup-column">
            <label> Select Date of Service </label>
                <input type="text" placeholder="select date for servicing" />
                </div>
              </div>
            </div>
              <button onClick={handleClosePopup}>Submit <FontAwesomeIcon icon={faArrowRight}></FontAwesomeIcon></button>
            </div>
          </div>
        )}

        <div className="service-list">
          {services.map(service => (
            <div key={service.id} className={`service-card ${service.selected ? 'selected' : ''}`}>
              <div className="service-logo">
                <img src={servicelogo} alt={service.name} />
              </div>
              <div className="service-info">
                <div className='service-name-meta' style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'baseline' }}>
                  <h4>{service.name}</h4>
                  <div className="service-meta">
                    <span>⭐ {service.rating}</span>
                    <span>📍 {service.location}</span>
                    <span>📏 {service.distance}</span>
                  </div>
                </div>
                <p>{service.description}</p>             
              </div>
              <div className="service-action">
                <p className="service-price"> <span style={{ color: '#FFBE00' }}>₹ </span>{service.price}</p>
                <button
                  className={`select-btn ${service.selected ? 'selected-btn' : ''}`}
                  onClick={() => handleSelect(service.id)}
                >
                  {service.selected ? 'Selected' : 'Select'}
                </button>
              </div>
            </div>
          ))}
        </div>
        <Link to="/CustomerDetails" onClick={onStepComplete}>
          <div className='service-partnerarrow'> 
            Proceed to Add Details <FontAwesomeIcon icon={faArrowRight} />
          </div>
        </Link>
      </div>
    </div>
  );
};

export default ServiceListing;
