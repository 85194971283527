import React from "react";
import "./ExteriortreatmentTable.css"; // Custom CSS file for styles

const InteriortreatmentTable = () => {
    return (
        <div className="treatment-table-container">
            <div className="treatment-details">
                <div className="treatment-detail-first">
                    <h5>Treatment Name</h5>
                    <p>Liquid Chemical Interior </p>
                    <p>Foam Based Interior </p>
                    <p>Antimicrobial Interior</p>
                    <p>Deep Cleaning</p>
                </div>

                <div className="treatment-detail">
                    <h5>Recommended</h5>
                    <p>Cost effective Treatment. Recommended on Fabric based Seat Covers</p>
                    <p>Premium Treatment.  Recommended on Leather Seat Covers for better life</p>
                    <p>Preferred when there is Fungus inside the Car</p>
                    <p>It is Done Post Removal of Seats</p>
                </div>

                <div className="treatment-detail">
                    <h5>Process</h5>
                    <p>A liquid chemical designed for application on seats, roof, or leather surfaces. </p>
                    <p>Foam based Chemical (Aerosol) to apply over Seats, Roof, Dashboard,</p> 
                    <p> Dashboard, Door Trims, All Plastic and Fabric/Leather Areas</p>
                    <p>Post removal of Seats, Clean the Carpet area and then vacuuming,</p>
                </div>

                <div className="treatment-detail">
                    <h5>0-11 Months</h5>
                    {/* <p>Yes</p>
          <p>No</p>
          <p>Yes</p>
          <p>No</p> */}
                </div>
            </div>
        </div>
    );
};

export default InteriortreatmentTable;
