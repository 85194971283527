import React from 'react';
import { CiCircleCheck } from 'react-icons/ci';
import './BookingSummary.css';

const BookingSummary = () => {
  return (
    <div className="customer-detail-container">
      <div className="rightsidebar">
        <h1>Booking Summary</h1>
        <div className="customer-form-container">
          <div className="successfullDiv">
            <div className="top">
              <div>
                <img src="/images/successfulllogo.png" alt="" />
              </div>

              <div>
                <p className="title">
                  Your Car Service Booking has been confirmed.
                </p>
              </div>
              <div className="serviveIdDiv">
                <p className="servicesId">Servive Id : 24AK090001</p>
              </div>
            </div>

            <div className="custo-Detail-Address">
              <div className="cust-Detail">
                <div className='cust-Detail-heading-left'>
                  <p>Customer Details</p>
                </div>
                <div className='cust-Detail-inside'>
                  <p>Mayank karode</p>
                  <p>BMW-X5</p>
                  <p>9999-999-999</p>
                  <p>xyz@gmail.com</p>
                  <p>dd/mm/yyyy</p>
                  <p>9 Am - 12 Noon</p>
                </div>
              </div>
              <div className="cust-Address">
                <div className="cust-Detail-heading-right">
                  <p className='cust-Detail-p1' >Addresses</p>
                </div>
                <div className='cust-Detail-child-2' >
                  <p className='cust-Detail-p2'>Pick-Up </p>
                  <p className='cust-Detail-p3'>162 Maharana Pratap Colony, Bhopal, (MP)</p>
                </div>
                
                <div className='cust-Detail-child-3' >
                  <p className="cust-Detail-p4">Drop</p>
                  <p className='cust-Detail-p5'>162 Maharana Pratap Colony, Bhopal, (MP)</p>
                </div>
                <p className='cust-Detail-p6'>Terms & Conditions</p>
              </div>
            </div>
          </div>

          <div className="successfullDetailsDiv">
            <span>
              <img
                className="navlogo"
                src="/images/navlogo.png"
                alt="navlogo"
              />
              <p> AutoKaar </p>
            </span>
            <p>service Provider Name: The Detailing Mafia</p>
            <p>Invoice No. : AK2409001</p>
            <div>
              <p>Services Selected</p>
              <ul className='booking-summary-right-ul-lis' >
                <li>
                  <p>UV Protection</p>
                  <p> 1500</p>
                </li>
                <li>
                  <p>Interior Enrichment</p>
                  <p> 2500</p>
                </li>
                <li>
                  <p>Surface Correction</p>
                  <p> 3000</p>
                </li>
                <li>
                  <p>UV Protection</p>
                  <p> 2000</p>
                </li>
              </ul>
              <div>
                <p>SubTotal</p>
                <p>9000</p>
              </div>
              <div>
                <p>Taxes & Convenience fees</p>
                <p>200</p>
              </div>
              <div>
                <p>Grand Total</p>
                <p>9200</p>
              </div>
              <div>
                <p>Amount Paid</p>
                <p>8280</p>
              </div>
              <div>
                <p>Amount Pending</p>
                <p>920</p>
              </div>
              <div>
                <p>Transaction date:</p>
                <p>12/10/2024</p>
              </div>
              <div>
                <p>Transaction id:</p>
                <p>428419556782</p>
              </div>
              <p>Download Invoice</p>
            </div>
          </div>
        </div>
        <div className="custo-fotterdiv">
          <p className="custo-support-p1"> Cancellation Policy</p>
          <p className="custo-support-p2">Contact Customer Support</p>
        </div>
      </div>
    </div>
  );
};

export default BookingSummary;