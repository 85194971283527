import React, { useState, useRef } from "react";
import carageImage from '../image/Carage.png';
import "./Carage.css";
import ExteriortreatmentTable from './ExteriortreatmentTable';
import InteriortreatmentTable from './InteriortreatmentTable';


const CarInfo = () => {
  const [showButtons, setShowButtons] = useState(false);
  const [carInfo, setCarInfo] = useState("");
  const [showTable, setShowTable] = useState(false); 
  const [treatment, setTreatment] = useState(""); 
  const carAgeRef = useRef(null);

  const getCarInfo = () => {
    const carAge = carAgeRef.current.value;
    if (carAge === "") {
      setShowButtons(false);
      return;
    }
    setShowButtons(true);
  };

  const submitTasks = (selectedTreatment) => {
  setShowButtons(false);
    carAgeRef.current.value = "";
    setCarInfo(""); 
    setTreatment(selectedTreatment); 
    setShowTable(selectedTreatment === "Exterior Rejuvenation" || selectedTreatment === "Interior Cleaning");
  };

  const handleCarInfoChange = (e) => {
    setCarInfo(e.target.value);
     setShowButtons(false);
  };

  return (
  <>
  <div className="Car-age">
    <div className="car-info-container">
      <div className="car-info-content">
        <h2 style={{ fontFamily: '"Chakra Petch", sans-serif' }}> Understand Your Car Needs </h2>
        <p> Please enter the age of your car to assess the appropriate level of care required...</p>
        <div className="input-group" style={{ marginRight: "20px" }}>
          <input 
            type="number" 
            ref={carAgeRef} 
            placeholder="Enter your car age (in years)" 
            min="0"
            max="50" 
            value={carInfo}
            onChange={handleCarInfoChange}
            onKeyDown={(e) => {
              if (e.key === 'Enter') {
                getCarInfo();
              }
            }}
          />
          <button onClick={getCarInfo}>Get Info</button>
        </div>
        
        <div id="carInfoResult">
        </div>
      </div>
    </div>
    <div className="Car-channel-partner">
    {showButtons && (
        <div className="TreatmentCategory">
        {carAgeRef.current?.value >= 1 && (
          <>
            <div className="treatmentpartone">
              <button onClick={() => submitTasks("Paint Protection Film")} style={{ marginLeft: "5px" }}> Paint Protection Film</button> 
              <button onClick={() => submitTasks("Ceramic Coating")} style={{ marginLeft: "5px" }}>Ceramic Coating</button>
              <button onClick={() => submitTasks("Interior Cleaning")} style={{ marginLeft: "5px" }}>Interior Cleaning</button>
              <button onClick={() => submitTasks("Exterior Rejuvenation")} style={{ marginLeft: "5px" }}>Exterior Rejuvenation</button>
            </div>
            <div className="treatmentparttwo">
              <button onClick={() => submitTasks("AC Care")} style={{ marginLeft: "5px" }}>AC Care</button>
              <button onClick={() => submitTasks("Antirust Coatings")} style={{ marginLeft: "5px" }}>Antirust Coatings</button>
              <button onClick={() => submitTasks("Engine Protection")} style={{ marginLeft: "5px" }}>Engine Protection</button>
              <button onClick={() => submitTasks("Windshield Restoration")} style={{ marginLeft: "5px" }}>Windshield Restoration</button>
              <button onClick={() => submitTasks("Headlamp Restoration")} style={{ marginLeft: "5px" }}>Headlamp Restoration</button>
            </div>          </>
  )}
</div>
        )}
    </div>
    </div>
    {showTable && treatment === "Exterior Rejuvenation" && <ExteriortreatmentTable />}
      {showTable && treatment === "Interior Cleaning" && <InteriortreatmentTable />}

        </>

  );
};

export default CarInfo;