import React, { useState ,useContext} from 'react';
import './Washing.css'; 
import engine1 from '../image/inside service/engine-car.jpg';
import engine2 from '../image/serviceimage/ENGINE PROTECTION.jpg';
import engine3 from '../image/inside service/Rodent Repellent.webp';
import image from '../image/washcar.jpg';
import ServiceSidebar from './ServiceSidebar';
import {faXmark, faArrowRight} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Link } from 'react-router-dom';
import { ServiceContext } from './ServiceContext'; // Import the context

const Engineprotection = () => {

    const { addService, removeService } = useContext(ServiceContext); // Use the context
    const [isAdded1, setIsAdded1] = useState(false);
    const [isAdded2, setIsAdded2] = useState(false);

    const handleAddToCart1 = () => {
        if (!isAdded1) {
            setIsAdded1(true);
            addService(); // Increment global service count
        }
    };
  
    const handleCancel1 = () => {
        if (isAdded1) {
            setIsAdded1(false);
            removeService(); // Decrement global service count
        }
    };

    const handleAddToCart2 = () => {
        if (!isAdded2) {
            setIsAdded2(true);
            addService(); // Increment global service count
        }
    };
  
    const handleCancel2 = () => {
        if (isAdded2) {
            setIsAdded2(false);
            removeService(); // Decrement global service count
        }
    };
    return (        
        <div className='washingpage-container' style={{display:'flex'}}>
        <ServiceSidebar />

        <div className="main-engine">
    <div className="services-contant">
            <div className="services-contact-form">
                <h2 style={{ fontFamily: '"Chakra Petch", sans-serif' }}>Engine Care</h2>
                <h3 style={{ fontFamily: '"Chakra Petch", sans-serif' }}>Rat Protection Treatment:</h3>
                <p>Provides up to 80% protection from rats, typically effective for 6 months.</p>
                <h3 style={{ fontFamily: '"Chakra Petch", sans-serif' }}>Engine Polish:</h3>

                <p>A polish applied to maintain the engine’s shine and appearance.</p>  </div>     
                 <div className="services-contact-image">
                     <img src={engine1} alt="Car" />
                 </div></div>
    
           <div className="midd-contant">
            <div className="midd-contact-form">
                <h2 style={{ fontFamily: '"Chakra Petch", sans-serif' }}>Engine Coating (Engine Lacquer)</h2>
                <p>Protects the engine from heat and dust, keeping it looking new.</p>
                </div>
                <div className="midd-contact-image">
                <img src={engine2} alt="Car" />
                <div className='add-cart'>
                {isAdded1 &&(
                <button className='cencel' onClick={handleCancel1}>
                    <FontAwesomeIcon icon={faXmark} />
                    </button> 
                )}
                              <button className={`added ${isAdded1 ? 'added-active' : ''}`} 
        onClick={handleAddToCart1}
    >
                {isAdded1 ? "Service Added" : "Add To Cart"}
              </button>            
            </div>
            </div>
                </div>

               <div className="midd-contant">
            <div className="midd-contact-form">
                <h2 style={{ fontFamily: '"Chakra Petch", sans-serif' }}>Rodent Repellent</h2>
                <p>Content to be provided</p> </div>     
                 <div className="midd-contact-image">
                     <img src={engine3} alt="Car" />
                     <div className='add-cart'>
                {isAdded2 &&(
                <button className='cencel' onClick={handleCancel2}>
                    <FontAwesomeIcon icon={faXmark} />
                    </button> 
                )}
                              <button className={`added ${isAdded2 ? 'added-active' : ''}`} 
        onClick={handleAddToCart2}
    >
                {isAdded2 ? "Service Added" : "Add To Cart"}
              </button>            
            </div>

                 </div>
        </div>
        <Link to="/ServiceListing" ><div className='service-partnerarrow'> 
            Proceed to Select Service Partner <FontAwesomeIcon icon={faArrowRight} />
          </div> </Link>        </div>
      </div>
    );
};

export default Engineprotection;
